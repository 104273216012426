// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".index_wave_amhbl {\n    display: flex;\n    align-items: center;\n    margin: 20px 0;\n  }\n\n  .index_bar_rXNEA {\n    width: 4px;\n    height: 10px;\n    margin: 0 2px;\n    background-color: #007bff;\n    border-radius: 2px;\n    transform: scaleY(0.4); /* 默认状态 */\n  }\n\n  .index_wave_amhbl.index_active_Cd5IS .index_bar_rXNEA {\n    animation: index_bounce_8imQu 1s infinite;\n  }\n\n  @keyframes index_bounce_8imQu {\n    0%, 100% {\n      transform: scaleY(0.8);\n    }\n    50% {\n      transform: scaleY(1.6);\n    }\n  }\n", "",{"version":3,"sources":["webpack://./src/components/SpeechRecognitionModal/Sound_waves/index.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,mBAAmB;IACnB,cAAc;EAChB;;EAEA;IACE,UAAU;IACV,YAAY;IACZ,aAAa;IACb,yBAAyB;IACzB,kBAAkB;IAClB,sBAAsB,EAAE,SAAS;EACnC;;EAEA;IACE,yCAA6B;EAC/B;;EAEA;IACE;MACE,sBAAsB;IACxB;IACA;MACE,sBAAsB;IACxB;EACF","sourcesContent":[".wave {\n    display: flex;\n    align-items: center;\n    margin: 20px 0;\n  }\n\n  .bar {\n    width: 4px;\n    height: 10px;\n    margin: 0 2px;\n    background-color: #007bff;\n    border-radius: 2px;\n    transform: scaleY(0.4); /* 默认状态 */\n  }\n\n  .wave.active .bar {\n    animation: bounce 1s infinite;\n  }\n\n  @keyframes bounce {\n    0%, 100% {\n      transform: scaleY(0.8);\n    }\n    50% {\n      transform: scaleY(1.6);\n    }\n  }\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wave": "index_wave_amhbl",
	"bar": "index_bar_rXNEA",
	"active": "index_active_Cd5IS",
	"bounce": "index_bounce_8imQu"
};
module.exports = ___CSS_LOADER_EXPORT___;
