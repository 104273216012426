import React from "react";
import PropTypes from "prop-types";
import Modal from "../modal/modal.jsx"; // 引入Modal组件
import styles from "./index.css"; // 样式文件
import SoundWaves from "./Sound_waves";

class SpeechRecognitionModal extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            isRecognizing: props.isAuto, // 根据模式初始化识别状态
            recognitionResult: "", // 存储语音识别的结果
            questionAnswerResult: "", // 存储智能问答的结果
            countdown: props.duration || 5, // 初始化倒计时
            mode: props.mode || "弹窗", // 模式（弹窗或舞台）
            isRecording: false, // 增加录音状态
        };

        this.recognition = null; // 语音识别实例
        this.mediaRecorder = null; // 录音实例
        this.audioChunks = []; // 存储录音数据
        this.countdownTimer = null; // 倒计时定时器
        this.uploadController = new AbortController(); // 中止上传请求
        this.recognitionController = new AbortController(); // 中止识别请求

        // 绑定方法
        this.handleStartRecognition = this.handleStartRecognition.bind(this);
        this.handleStopRecognition = this.handleStopRecognition.bind(this);
        this.handleCloseModal = this.handleCloseModal.bind(this);
    }

    // 初始化语音识别或录音
    componentDidMount() {
        if ("webkitSpeechRecognition" in window && false) {
            // 初始化 webkitSpeechRecognition
            this.initSpeechRecognition();
        } else {
            console.warn("浏览器不支持 Web Speech API，使用录音方式");
            // 启动录音和倒计时
            if (this.props.isAuto) {
                this.startRecording();
                this.startCountdown();
            }
        }
    }

    // 初始化 webkitSpeechRecognition
    initSpeechRecognition() {
        this.recognition = new window.webkitSpeechRecognition();
        this.recognition.continuous = false;
        this.recognition.interimResults = false;
        this.recognition.lang = "zh-CN";

        // 处理识别结果
        this.recognition.onresult = (event) => {
            const result = event.results[0][0].transcript;
            this.setState({ recognitionResult: result, isRecognizing: false });

            // 将结果发送回VM
            this.props.vm.runtime.emit("SPEECH_RECOGNITION_DONE", result);

            // 处理识别结束逻辑
            this.handleRecognitionEnd();
        };

        // 处理识别错误
        this.recognition.onerror = (event) => {
            console.error("语音识别错误：", event.error);
            this.setState({ isRecognizing: false });
            this.handleRecognitionEnd();
        };

        // 处理识别结束
        this.recognition.onend = () => {
            this.setState({ isRecognizing: false });
            console.log("语音识别结束");
            this.handleRecognitionEnd();
        };

        // 启动识别和倒计时（自动模式）
        if (this.props.isAuto) {
            this.handleStartRecognition();
            this.startCountdown();

            // 增加5秒限制
            setTimeout(() => {
                if (this.state.isRecognizing) {
                    this.handleStopRecognition(); // 停止识别
                }
            }, (this.props.duration || 5) * 1000);
        }
    }

    // 启动录音
    async startRecording() {
        try {
            const stream = await navigator.mediaDevices.getUserMedia({
                audio: true,
            });
            this.props.vm.emit("SPEECH_RECOGNITION_STATUS", "开始说话");
            this.mediaRecorder = new MediaRecorder(stream);
            this.audioChunks = []; // 清空录音数据

            // 收集录音数据
            this.mediaRecorder.ondataavailable = (event) => {
                this.audioChunks.push(event.data);
            };

            // 录音结束时上传
            this.mediaRecorder.onstop = this.handleRecordingStop;

            // 启动录音并设置状态
            this.mediaRecorder.start();
            this.setState({ isRecognizing: true, isRecording: true });
            console.log("开始录音...");
            this.props.vm.emit("SPEECH_RECOGNITION_STATUS", "录音中");
            // 设置默认录音时长为 5 秒或通过 props.duration 进行自定义
            setTimeout(() => {
                if (this.mediaRecorder && this.state.isRecording) {
                    this.handleStopRecognition(); // 停止录音
                }
            }, (this.props.duration || 5) * 1000);
        } catch (error) {
            console.error("无法访问麦克风:", error);
            alert("无法访问麦克风，请检查浏览器权限设置");
        }
    }

    // 停止录音并上传
    handleRecordingStop = async () => {
        this.props.vm.emit("SPEECH_RECOGNITION_STATUS", "识别中");
        // 创建音频Blob对象
        const audioBlob = new Blob(this.audioChunks, { type: "audio/wav" });

        // 使用AudioContext分析音频数据
        const hasValidAudio = await this.hasValidAudio(audioBlob);
        if (!hasValidAudio) {
            console.warn("录音音量过低或仅包含杂音");
            alert("录音无效，请重试");
            this.setState({ isRecognizing: false, isRecording: false });
            return;
        }

        // 如果音频有效，继续上传
        const formData = new FormData();
        formData.append("file", audioBlob, "audio.wav");

        try {
            // 上传音频文件到OSS
            const uploadResponse = await fetch(
                "https://backend.aileader.cn/v1/upload_to_oss",
                {
                    method: "POST",
                    body: formData,
                    signal: this.uploadController.signal, // 中止信号
                }
            );
            const uploadResult = await uploadResponse.json();

            if (uploadResponse.ok && uploadResult.data?.url) {
                const audioUrl = uploadResult.data.url;
                console.log("音频上传成功，URL:", audioUrl);

                // 调用自定义接口进行语音转译
                await this.handleRecognition(audioUrl);
            } else {
                console.error(
                    "音频上传失败:",
                    uploadResult?.message || "未知错误"
                );
                alert("音频上传失败，请重试");
            }
        } catch (error) {
            if (error.name === "AbortError") {
                console.log("音频上传被中止");
            } else {
                console.error("音频上传过程出错:", error);
                alert("音频上传过程出错，请重试");
            }
        }
    };

    // 辅助方法：检测音频是否包含有效语音
    hasValidAudio = async (audioBlob) => {
        return new Promise((resolve) => {
            const audioContext = new (window.AudioContext ||
                window.webkitAudioContext)();
            const reader = new FileReader();

            reader.onloadend = () => {
                audioContext.decodeAudioData(reader.result, (buffer) => {
                    const channelData = buffer.getChannelData(0); // 检测第一个声道
                    const threshold = 0.02; // 设置检测阈值
                    const minValidSamples = Math.floor(
                        channelData.length * 0.05
                    ); // 设定至少5%的样本需要超过阈值

                    // 检测样本中超过阈值的数量
                    const validSampleCount = channelData.filter(
                        (sample) => Math.abs(sample) > threshold
                    ).length;

                    // 如果有效样本数超过最小有效样本数，则认为有有效语音
                    const hasValidAudio = validSampleCount > minValidSamples;

                    resolve(hasValidAudio);
                });
            };

            reader.readAsArrayBuffer(audioBlob);
        });
    };

    // 调用自定义接口进行语音转译
    handleRecognition = async (audioUrl) => {
        try {
            const response = await fetch(
                "https://qwen.aileader.cn/audio-recognizer",
                {
                    method: "POST",
                    headers: { "Content-Type": "application/json" },
                    body: JSON.stringify({ audio_url: audioUrl }),
                    signal: this.recognitionController.signal, // 中止信号
                }
            );

            // 检查响应状态
            if (!response.ok) {
                throw new Error(`服务器响应失败，状态码：${response.status}`);
            }

            const data = await response.json();
            console.log("语音识别结果:", data);
            // 检查是否存在结果
            let text = "";
            data?.result?.sentences?.forEach((item) => {
                text += item.text;
            });
            const { type } = this.props;
            if (type === "recognition") {
                if (text) {
                    this.setState({
                        recognitionResult: text,
                        isRecognizing: false,
                    });

                    // 将结果发送回VM
                    this.props.vm.runtime.emit("SPEECH_RECOGNITION_DONE", text);

                    // 如果是自动模式，识别结束后停止倒计时并延迟三秒关闭弹窗
                    if (this.props.isAuto) {
                        clearInterval(this.countdownTimer);
                        setTimeout(this.handleCloseModal, 3000);
                    }
                } else {
                    throw new Error("未找到识别结果");
                }
            } else {
                if (text) {
                    const requestBody = {
                        text: text,
                        stream: false,
                    };
                    await fetch(
                        "https://qwen.aileader.cn/multimodal-conversation",
                        {
                            method: "POST",
                            headers: {
                                "Content-Type": "application/json",
                            },
                            body: JSON.stringify(requestBody),
                        }
                    )
                        .then(async (response) => {
                            const result = await response.json();
                            console.log(
                                result,
                                result.output?.choices?.[0]?.message
                                    ?.content?.[0]?.text
                            );
                            if (result?.status_code === 200) {
                                const recognitionText =
                                    result.output?.choices?.[0]?.message
                                        ?.content?.[0]?.text;
                                if (recognitionText) {
                                    this.props.vm.runtime.emit(
                                        "SPEECH_CALCULATOR_DONE",
                                        recognitionText
                                    );
                                    this.setState({
                                        questionAnswerResult: recognitionText,
                                        isRecognizing: false,
                                    });
                                } else {
                                    console.error("识别结果为空");
                                    alert("识别结果为空，请重试");
                                }
                            } else {
                                console.error(
                                    "识别失败:",
                                    result?.message || "未知错误"
                                );
                                alert(
                                    `识别失败: ${result?.message || "请重试"}`
                                );
                            }
                        })
                        .catch((error) => {
                            console.error("调用接口时出错:", error);
                            alert("调用接口时出错，请重试");
                        })
                        .finally(() => {
                            // 无论成功还是失败，3秒后关闭弹窗
                            // if (!this.props.isAuto) return;
                            setTimeout(this.handleCloseModal, 3000);
                        });

                    // 如果是自动模式，识别结束后停止倒计时并延迟三秒关闭弹窗
                    if (this.props.isAuto) {
                        clearInterval(this.countdownTimer);
                        setTimeout(this.handleCloseModal, 3000);
                    }
                } else {
                    throw new Error("未找到识别结果");
                }
            }
        } catch (error) {
            if (error.name === "AbortError") {
                console.log("语音识别请求被中止");
            } else {
                console.error("调用自定义接口时出错:", error);
                alert("调用自定义接口时出错，请重试");
            }
        } finally {
            // 无论成功还是失败，3秒后关闭弹窗
            setTimeout(this.handleCloseModal, 3000);
        }
    };

    // 识别结束处理
    handleRecognitionEnd() {
        if (this.props.isAuto) {
            clearInterval(this.countdownTimer);
            setTimeout(this.handleCloseModal, 3000);
        }
    }

    // 启动倒计时
    startCountdown() {
        this.countdownTimer = setInterval(() => {
            this.setState((prevState) => {
                if (prevState.countdown > 1) {
                    return { countdown: prevState.countdown - 1 };
                } else {
                    clearInterval(this.countdownTimer);
                    this.handleStopRecognition();
                    return { countdown: 0 };
                }
            });
        }, 1000);
    }

    // 开始语音识别
    handleStartRecognition() {
        if (this.recognition) {
            this.setState({ isRecognizing: true, recognitionResult: "" });

            // 请求麦克风权限并启动识别
            navigator.mediaDevices
                .getUserMedia({ audio: true })
                .then(() => {
                    this.recognition.start(); // 开始识别
                    console.log("开始语音识别...");
                })
                .catch((error) => {
                    console.error("无法访问麦克风:", error);
                    alert("请允许访问麦克风才能进行语音识别");
                });
        } else {
            console.log("使用自定义录音进行语音识别...");
            this.startRecording();
        }
    }

    // 停止语音识别或录音
    handleStopRecognition() {
        if (this.recognition && this.state.isRecognizing) {
            this.recognition.stop(); // 停止识别
        } else if (this.mediaRecorder && this.state.isRecording) {
            this.mediaRecorder.stop(); // 停止录音
        }
        this.setState({ isRecognizing: false, isRecording: false });
        console.log("停止语音识别或录音...");
    }

    // 关闭弹窗的方法
    handleCloseModal() {
        clearInterval(this.countdownTimer); // 清除倒计时

        // 停止语音识别
        if (this.recognition && this.state.isRecognizing) {
            this.recognition.stop();
            console.log("语音识别已停止");
        }

        // 停止录音
        if (this.mediaRecorder && this.state.isRecognizing) {
            this.mediaRecorder.stop();
            console.log("录音已停止");
        }

        // 中止音频上传和语音识别请求
        this.uploadController.abort();
        this.recognitionController.abort();

        // 重置识别状态
        this.setState({ isRecognizing: false });

        // 调用父组件传递的 onClose 方法
        if (this.props.onClose) {
            this.props.onClose();
        }
    }

    render() {
        const {
            isRecognizing,
            recognitionResult,
            questionAnswerResult,
            countdown,
            mode,
            isRecording,
        } = this.state;
        const { type } = this.props;
        // 舞台模式的录音过程和结果显示
        if (mode === "舞台") {
            return (
                <div className={styles.stageContainer}>
                    <div className={styles.recognitionBox}>
                        {isRecognizing ? (
                            <p>舞台录音中...（{countdown} 秒）</p>
                        ) : (
                            <p>点击按钮开始舞台录音</p>
                        )}
                    </div>
                    <div className={styles.recognitionResult}>
                        <strong>结果：</strong>
                        <span className={styles.resultText}>
                            {recognitionResult ||
                                questionAnswerResult ||
                                "暂无结果"}
                        </span>
                    </div>
                </div>
            );
        }

        // 弹窗模式的录音过程和结果显示
        return (
            <Modal
                className={styles.modalContent}
                onRequestClose={this.handleCloseModal}
                contentLabel={`语音识别（${
                    this.props.isAuto ? "自动模式" : "手动模式"
                })`}
                id="speech-recognition-modal"
            >
                <div className={styles.container}>
                    <div className={styles.recognitionBox}>
                        <SoundWaves
                            isRecognizing={isRecognizing || isRecording}
                        />
                    </div>
                    <div className={styles.recognitionResult}>
                        <strong>
                            {type === "recognition"
                                ? "识别结果："
                                : "智能问答结果："}
                        </strong>
                        <span className={styles.resultText}>
                            {recognitionResult ||
                                questionAnswerResult ||
                                "暂无结果"}
                        </span>
                    </div>
                    {!this.props.isAuto && (
                        <div className={styles.buttons}>
                            <button
                                className={styles.startButton}
                                onClick={this.handleStartRecognition}
                                disabled={isRecognizing}
                            >
                                开始识别
                            </button>
                            <button
                                className={styles.stopButton}
                                onClick={this.handleStopRecognition}
                                disabled={!isRecognizing}
                            >
                                停止识别
                            </button>
                        </div>
                    )}
                    {isRecognizing ? (
                        <p>
                            正在识别/录音中...
                            {this.props.isAuto && `（${countdown} 秒）`}
                        </p>
                    ) : (
                        <p>点击按钮开始识别或录音</p>
                    )}
                </div>
            </Modal>
        );
    }
}

SpeechRecognitionModal.propTypes = {
    isAuto: PropTypes.bool, // 是否为自动模式
    duration: PropTypes.number, // 倒计时总时长
    mode: PropTypes.string, // 模式（弹窗或舞台）
    onClose: PropTypes.func.isRequired, // 关闭弹窗的回调函数
    type: PropTypes.string.isRequired, // 添加类型属性
};

export default SpeechRecognitionModal;
