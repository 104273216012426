import React, { useEffect, useRef, useState } from "react";
import PropTypes from "prop-types";
import Modal from "../modal/modal.jsx";
import styles from "./index.css";
import classNames from "classnames";

const ImageRecognitionModal = ({ visible, data = {}, onClose, vm }) => {
    const videoRef = useRef(null);
    const fileInputRef = useRef(null);
    const countdownRef = useRef(null);
    const autoCloseTimeout = useRef(null);

    const [imageData, setImageData] = useState(null);
    const [isVideoReady, setIsVideoReady] = useState(false);
    const [isUploadMode, setIsUploadMode] = useState(false);
    const [resultsName, setResultsName] = useState(null);
    const [countdown, setCountdown] = useState(null);
    const [isLoading, setIsLoading] = useState(false); // 添加加载状态

    const isAuto = data.isAuto || false;
    const category = data.TYPE || "物体";

    const categoryTextMap = {
        ANIMAL: "动物",
        FRUIT: "水果",
        NUMBER: "数字",
        LETTER: "字母",
        TRASH: "垃圾",
        SHAPE: "形状",
        COLOR: "颜色",
        GESTURE: "手势",
        LICENSE_PLATE: "车牌",
        MACAU_LICENSE_PLATE: "澳门车牌",
        TAIWAN_LICENSE_PLATE: "台湾车牌",
        AGE: "年龄",
        EXPRESSION: "表情",
        OCR: "文字",
        QR_CODE: "二维码",
    };

    const startCamera = () => {
        navigator.mediaDevices
            .getUserMedia({ video: true })
            .then((stream) => {
                if (videoRef.current) videoRef.current.srcObject = stream;
            })
            .catch((err) => console.error("Error accessing camera:", err));
    };

    const stopCamera = () => {
        if (videoRef.current?.srcObject) {
            videoRef.current.srcObject
                .getTracks()
                .forEach((track) => track.stop());
            videoRef.current.srcObject = null;
        }
    };

    const handleVideoLoaded = () => setIsVideoReady(true);

    const handleCapture = () => {
        if (!isVideoReady || !videoRef.current) {
            alert("视频未加载完成，请稍后再试");
            return;
        }

        const canvas = document.createElement("canvas");
        const context = canvas.getContext("2d");
        canvas.width = videoRef.current.videoWidth;
        canvas.height = videoRef.current.videoHeight;

        if (data.MODE === "MIRROR_CAPTURE") {
            context.translate(canvas.width, 0);
            context.scale(-1, 1);
        }

        context.drawImage(videoRef.current, 0, 0, canvas.width, canvas.height);
        setImageData(canvas.toDataURL("image/png"));
    };

    const handleFileUpload = (e) => {
        const file = e.target.files[0];
        if (!file) return;

        const reader = new FileReader();
        reader.onload = (event) => setImageData(event.target.result);
        reader.readAsDataURL(file);
    };

    const handleUploadAndRecognition = async () => {
        if (!imageData) {
            alert("请先捕获或上传图像");
            return;
        }

        setIsLoading(true); // 开始加载
        try {
            const blob = await fetch(imageData).then((res) => res.blob());
            const formData = new FormData();
            formData.append(
                "file",
                new File([blob], `photo_${Date.now()}.png`, {
                    type: "image/png",
                })
            );

            const uploadResponse = await fetch(
                "https://backend.aileader.cn/v1/upload_to_oss",
                { method: "POST", body: formData }
            );
            const uploadResult = await uploadResponse.json();

            if (uploadResponse.ok && uploadResult.data?.url) {
                await handleRecognition(uploadResult.data.url);
            } else {
                alert("图片上传失败，请重试");
            }
        } catch (error) {
            console.error("上传请求出错:", error);
            alert("上传请求出错，请检查网络连接");
        } finally {
            setIsLoading(false); // 结束加载
            autoCloseTimeout.current = setTimeout(onClose, 3000);
        }
    };

    const handleRecognition = async (imageUrl) => {
        try {
            const categoryText = categoryTextMap[data?.TYPE] || "动物";
            const requestBody = {
                text:
                    data?.TEXT ||
                    `请识别图片中的【${categoryText}】，仅回答${categoryText}名称即可。`,
                image: [imageUrl],
                messages: [],
                stream: false,
            };

            const response = await fetch(
                "https://qwen.aileader.cn/multimodal-conversation",
                {
                    method: "POST",
                    headers: { "Content-Type": "application/json" },
                    body: JSON.stringify(requestBody),
                }
            );
            const result = await response.json();

            if (result?.status_code === 200) {
                const recognitionText =
                    result.output?.choices?.[0]?.message?.content?.[0]?.text;
                if (recognitionText) {
                    setResultsName(recognitionText);
                    vm.runtime.emit("IMAGE_RECOGNITION_RESULT", {
                        result: recognitionText,
                        TYPE: data.TYPE,
                        blockId: data.blockId,
                    });
                } else {
                    alert("识别结果为空，请重试");
                }
            } else {
                alert(`识别失败: ${result?.message || "请重试"}`);
            }
        } catch (error) {
            console.error("识别请求出错:", error);
            alert("识别请求出错，请检查网络连接");
        }
    };

    const handleSwitchMode = () => {
        setIsUploadMode(!isUploadMode);
        setImageData(null);
        setResultsName(null);
        if (!isUploadMode) startCamera();
    };

    const handleReset = () => {
        setImageData(null);
        setResultsName(null);
        setIsUploadMode(false);
        startCamera();
    };

    useEffect(() => {
        if (visible && !isUploadMode && !imageData) startCamera();
        return stopCamera;
    }, [visible, isUploadMode, imageData]);

    useEffect(() => {
        if (isVideoReady && isAuto && !imageData) {
            const delay = parseInt(data.DELAY || 5, 10);
            setCountdown(delay);
            countdownRef.current = setInterval(() => {
                setCountdown((prev) => {
                    if (prev <= 1) {
                        clearInterval(countdownRef.current);
                        setCountdown(null);
                        handleCapture();
                        return null;
                    }
                    return prev - 1;
                });
            }, 1000);
        }
        return () => clearInterval(countdownRef.current);
    }, [isVideoReady, isAuto, imageData]);

    useEffect(() => {
        if (isAuto && imageData) handleUploadAndRecognition();
    }, [imageData]);

    return (
        <Modal
            id="image-recognition-modal"
            className={styles.modalContent}
            contentLabel={`图像识别 - ${
                isUploadMode ? "本地上传" : "视频拍摄"
            }`}
            onRequestClose={onClose}
            isOpen={visible}
        >
            <div className={styles.container}>
                <div className={styles.title}>
                    {resultsName ? `识别结果：${resultsName}` : "图像识别"}
                </div>
                {imageData ? (
                    <div className={styles.previewContainer}>
                        <img
                            src={imageData}
                            alt="捕获或上传的图像"
                            className={styles.previewImage}
                        />
                    </div>
                ) : isUploadMode ? (
                    <div className={styles.previewContainer}>
                        <label
                            htmlFor="fileUpload"
                            className={styles.uploadLabel}
                        >
                            点击选择本地图片
                            <input
                                id="fileUpload"
                                type="file"
                                accept="image/*"
                                onChange={handleFileUpload}
                                className={styles.uploadInput}
                                ref={fileInputRef}
                            />
                        </label>
                    </div>
                ) : (
                    <div className={styles.videoContainer}>
                        <video
                            ref={videoRef}
                            autoPlay
                            className={styles.videoElement}
                            onLoadedMetadata={handleVideoLoaded}
                            style={
                                data.MODE === "MIRROR_CAPTURE"
                                    ? { transform: "scaleX(-1)" }
                                    : {}
                            }
                        />
                    </div>
                )}
                <div className={styles.buttonRow}>
                    <button
                        className={classNames(styles.switchButton, {
                            [styles.disabledButton]:
                                countdown !== null || isAuto || isLoading,
                        })}
                        onClick={handleSwitchMode}
                        disabled={countdown !== null || isAuto || isLoading}
                    >
                        切换为{isUploadMode ? "视频拍摄" : "本地上传"}
                    </button>
                    {!imageData && !isUploadMode && (
                        <button
                            className={classNames(styles.actionButton, {
                                [styles.disabledButton]:
                                    countdown !== null || isAuto || isLoading,
                            })}
                            onClick={handleCapture}
                            disabled={countdown !== null || isAuto || isLoading}
                        >
                            捕获图像
                            {countdown !== null && (
                                <div className={styles.countdownOverlay}>
                                    请准备，{countdown}s后拍照
                                </div>
                            )}
                        </button>
                    )}
                    {imageData && (
                        <button
                            className={classNames(styles.actionButton, {
                                [styles.disabledButton]:
                                    countdown !== null || isAuto || isLoading,
                            })}
                            onClick={handleReset}
                            disabled={countdown !== null || isAuto || isLoading}
                        >
                            重新选择
                        </button>
                    )}
                    <button
                        className={classNames(styles.actionButton, {
                            [styles.disabledButton]:
                                countdown !== null || isAuto || isLoading,
                        })}
                        onClick={handleUploadAndRecognition}
                        disabled={countdown !== null || isAuto || isLoading}
                    >
                        开始识别
                    </button>
                </div>
                {/* 加载覆盖层 */}
                {isLoading && (
                    <div className={styles.loadingOverlay}>
                        <div className={styles.loadingSpinner}></div>
                        <div className={styles.loadingText}>
                            处理中，请稍候...
                        </div>
                    </div>
                )}
            </div>
        </Modal>
    );
};

ImageRecognitionModal.propTypes = {
    visible: PropTypes.bool.isRequired,
    data: PropTypes.object,
    onClose: PropTypes.func.isRequired,
    vm: PropTypes.object.isRequired,
};

export default ImageRecognitionModal;
