import React from "react";
import styles from "./index.css"; // 引入模块化样式

const SoundWaves = ({ isRecognizing }) => {
    return (
        <div className={`${styles.wave} ${isRecognizing ? styles.active : ""}`}>
            {Array.from({ length: 30 }).map((_, index) => (
                <div
                    key={index}
                    className={styles.bar}
                    style={{ animationDelay: `${index * 0.05}s` }}
                ></div>
            ))}
        </div>
    );
};

export default SoundWaves;
