// import JSZip from "jszip";
// import Blockly from "scratch-blocks"; // 假设你已经在项目中引入了 scratch-blocks

// // 将 Blockly Block 转换为 Scratch 3 Block
// function convertBlocklyBlockToScratchBlock(block) {
//     const scratchBlock = {
//         opcode: block.type, // 积木的类型
//         next: block.getNextBlock() ? block.getNextBlock().id : null,
//         parent: block.getParent() ? block.getParent().id : null,
//         inputs: {},
//         fields: {},
//         shadow: false,
//         topLevel: !block.getParent(),
//         x: block.xy_.x,
//         y: block.xy_.y,
//     };

//     // 处理输入（inputs）
//     block.inputList.forEach((input) => {
//         const inputName = input.name;
//         const connection = input.connection;
//         if (connection) {
//             const targetBlock = connection.targetBlock();
//             if (targetBlock) {
//                 scratchBlock.inputs[inputName] = [1, targetBlock.id];
//             } else {
//                 // 如果没有连接的积木，可能有默认值（阴影积木）
//                 const shadowBlock = connection.shadowBlock_;
//                 if (shadowBlock) {
//                     scratchBlock.inputs[inputName] = [1, shadowBlock.id];
//                 } else {
//                     // 没有连接，使用空值
//                     scratchBlock.inputs[inputName] = [1, null];
//                 }
//             }
//         }
//     });

//     // 处理字段（fields）
//     block.inputList.forEach((input) => {
//         input.fieldRow.forEach((field) => {
//             const fieldName = field.name;
//             if (fieldName) {
//                 scratchBlock.fields[fieldName] = [field.getValue()];
//             }
//         });
//     });

//     return scratchBlock;
// }

// export async function generateSb3FileAndUpload(workspaceXMLString, uploadUrl) {
//     const zip = new JSZip();

//     try {
//         // 解析 workspaceXMLString 为 Blockly Blocks
//         const workspaceXML = Blockly.Xml.textToDom(workspaceXMLString);
//         const workspace = new Blockly.Workspace();
//         Blockly.Xml.domToWorkspace(workspaceXML, workspace);

//         // 将积木数据转换为 Scratch 的 blocks 数据格式
//         const blocksData = {};
//         const allBlocks = workspace.getAllBlocks();

//         allBlocks.forEach((block) => {
//             const scratchBlock = convertBlocklyBlockToScratchBlock(block);
//             blocksData[block.id] = scratchBlock;
//         });

//         // 创建一个名为 project.json 的 JSON 文件，包含项目的元数据和脚本
//         const projectJSON = {
//             targets: [
//                 {
//                     isStage: true,
//                     name: "Stage",
//                     variables: {},
//                     lists: {},
//                     broadcasts: {},
//                     blocks: {},
//                     comments: {},
//                     currentCostume: 0,
//                     costumes: [
//                         {
//                             assetId: "cd21514d0531fdffb22204e0ec5ed84a",
//                             name: "backdrop1",
//                             bitmapResolution: 1,
//                             md5ext: "cd21514d0531fdffb22204e0ec5ed84a.svg",
//                             dataFormat: "svg",
//                             rotationCenterX: 240,
//                             rotationCenterY: 180,
//                         },
//                     ],
//                     sounds: [],
//                     volume: 100,
//                     layerOrder: 0,
//                     tempo: 60,
//                     videoTransparency: 50,
//                     videoState: "on",
//                     textToSpeechLanguage: null,
//                 },
//                 {
//                     isStage: false,
//                     name: "Sprite1",
//                     variables: {},
//                     lists: {},
//                     broadcasts: {},
//                     blocks: blocksData,
//                     comments: {},
//                     currentCostume: 0,
//                     costumes: [
//                         {
//                             assetId: "cd21514d0531fdffb22204e0ec5ed84a",
//                             name: "costume1",
//                             bitmapResolution: 1,
//                             md5ext: "cd21514d0531fdffb22204e0ec5ed84a.svg",
//                             dataFormat: "svg",
//                             rotationCenterX: 240,
//                             rotationCenterY: 180,
//                         },
//                     ],
//                     sounds: [],
//                     volume: 100,
//                     layerOrder: 1,
//                     visible: true,
//                     x: 0,
//                     y: 0,
//                     size: 100,
//                     direction: 90,
//                     draggable: false,
//                     rotationStyle: "all around",
//                 },
//             ],
//             monitors: [],
//             extensions: [],
//             meta: {
//                 semver: "3.0.0",
//                 vm: "0.2.0-prerelease.20210909140915",
//                 agent: "Mozilla/5.0",
//             },
//         };

//         // 将 project.json 添加到 zip
//         zip.file("project.json", JSON.stringify(projectJSON));

//         // 添加造型文件到 zip（确保文件名与 md5ext 匹配）
//         const svgContent =
//             '<svg xmlns="http://www.w3.org/2000/svg" version="1.1"><rect width="100%" height="100%" fill="white"/></svg>';
//         zip.file("cd21514d0531fdffb22204e0ec5ed84a.svg", svgContent);

//         // 生成 ZIP 文件（.sb3文件内容）
//         const sb3Content = await zip.generateAsync({ type: "blob" });

//         // 构造 FormData 来上传文件
//         const formData = new FormData();
//         formData.append("file", sb3Content, "test.sb3");

//         // 使用 fetch 或者 axios 将文件上传到接口
//         const response = await fetch(uploadUrl, {
//             method: "POST",
//             body: formData,
//         }).then((response) => response.json())
//         if (response.message === "Conversion successful") {
//             return response.python_code;
//         } else {
//             console.error("文件上传失败", response.statusText);
//         }
//     } catch (error) {
//         console.error("Error generating or uploading SB3 file:", error);
//     }
// }

import JSZip from "jszip";
import Blockly from "scratch-blocks"; // 假设你已经在项目中引入了 scratch-blocks

// 将 Blockly Block 转换为 Scratch 3 Block
function convertBlocklyBlockToScratchBlock(block) {
    const scratchBlock = {
        opcode: block.type, // 积木的类型
        next: block.getNextBlock() ? block.getNextBlock().id : null,
        parent: block.getParent() ? block.getParent().id : null,
        inputs: {},
        fields: {},
        shadow: false,
        topLevel: !block.getParent(),
        x: block.xy_.x,
        y: block.xy_.y,
    };

    // 处理输入（inputs）
    block.inputList.forEach((input) => {
        const inputName = input.name;
        const connection = input.connection;
        if (connection) {
            const targetBlock = connection.targetBlock();
            if (targetBlock) {
                scratchBlock.inputs[inputName] = [1, targetBlock.id];
            } else {
                // 如果没有连接的积木，可能有默认值（阴影积木）
                const shadowBlock = connection.shadowBlock_;
                if (shadowBlock) {
                    scratchBlock.inputs[inputName] = [1, shadowBlock.id];
                } else {
                    // 没有连接，使用空值
                    scratchBlock.inputs[inputName] = [1, null];
                }
            }
        }
    });

    // 处理字段（fields）
    block.inputList.forEach((input) => {
        input.fieldRow.forEach((field) => {
            const fieldName = field.name;
            if (fieldName) {
                scratchBlock.fields[fieldName] = [field.getValue()];
            }
        });
    });

    return scratchBlock;
}

export async function generateSb3FileAndUpload(workspaceXMLString, uploadUrl) {
    const zip = new JSZip();

    try {
        // 解析 workspaceXMLString 为 Blockly Blocks
        const workspaceXML = Blockly.Xml.textToDom(workspaceXMLString);
        const workspace = new Blockly.Workspace();
        Blockly.Xml.domToWorkspace(workspaceXML, workspace);

        // 将积木数据转换为 Scratch 的 blocks 数据格式
        const blocksData = {};
        const allBlocks = workspace.getAllBlocks();

        allBlocks.forEach((block) => {
            const scratchBlock = convertBlocklyBlockToScratchBlock(block);
            blocksData[block.id] = scratchBlock;
        });

        // 创建一个名为 project.json 的 JSON 文件，包含项目的元数据和脚本
        const projectJSON = {
            targets: [
                {
                    isStage: true,
                    name: "Stage",
                    variables: {},
                    lists: {},
                    broadcasts: {},
                    blocks: {},
                    comments: {},
                    currentCostume: 0,
                    costumes: [
                        {
                            assetId: "cd21514d0531fdffb22204e0ec5ed84a",
                            name: "backdrop1",
                            bitmapResolution: 1,
                            md5ext: "cd21514d0531fdffb22204e0ec5ed84a.svg",
                            dataFormat: "svg",
                            rotationCenterX: 240,
                            rotationCenterY: 180,
                        },
                    ],
                    sounds: [],
                    volume: 100,
                    layerOrder: 0,
                    tempo: 60,
                    videoTransparency: 50,
                    videoState: "on",
                    textToSpeechLanguage: null,
                },
                {
                    isStage: false,
                    name: "Sprite1",
                    variables: {},
                    lists: {},
                    broadcasts: {},
                    blocks: blocksData,
                    comments: {},
                    currentCostume: 0,
                    costumes: [
                        {
                            assetId: "cd21514d0531fdffb22204e0ec5ed84a",
                            name: "costume1",
                            bitmapResolution: 1,
                            md5ext: "cd21514d0531fdffb22204e0ec5ed84a.svg",
                            dataFormat: "svg",
                            rotationCenterX: 240,
                            rotationCenterY: 180,
                        },
                    ],
                    sounds: [],
                    volume: 100,
                    layerOrder: 1,
                    visible: true,
                    x: 0,
                    y: 0,
                    size: 100,
                    direction: 90,
                    draggable: false,
                    rotationStyle: "all around",
                },
            ],
            monitors: [],
            extensions: [],
            meta: {
                semver: "3.0.0",
                vm: "0.2.0-prerelease.20210909140915",
                agent: "Mozilla/5.0",
            },
        };

        // 将 project.json 添加到 zip
        zip.file("project.json", JSON.stringify(projectJSON));

        // 添加造型文件到 zip（确保文件名与 md5ext 匹配）
        const svgContent =
            '<svg xmlns="http://www.w3.org/2000/svg" version="1.1"><rect width="100%" height="100%" fill="white"/></svg>';
        zip.file("cd21514d0531fdffb22204e0ec5ed84a.svg", svgContent);

        // 生成 ZIP 文件（.sb3文件内容）
        const sb3Content = await zip.generateAsync({ type: "blob" });

        // 获取当前时间戳，生成唯一文件名
        const timestamp = new Date().toISOString().replace(/[-:.]/g, "");

        // 构造 FormData 来上传文件，并使用带时间戳的文件名
        const formData = new FormData();
        formData.append("file", sb3Content, `project_${timestamp}.sb3`);

        // 创建并下载 .sb3 文件
        const url = URL.createObjectURL(sb3Content);
        const a = document.createElement("a");
        a.href = url;
        a.download = "project.sb3";
        a.click();
        URL.revokeObjectURL(url); // 下载完成后释放URL对象

        // 使用 fetch 将文件上传到接口
        const response = await fetch(uploadUrl, {
            method: "POST",
            body: formData,
        }).then((response) => response.json());

        if (response.message === "Conversion successful") {
            return response.python_code; // 返回 Python 代码
        } else {
            console.error("文件上传失败", response.statusText);
            return null;
        }
    } catch (error) {
        console.error("Error generating or uploading SB3 file:", error);
        return null;
    }
}
