import React, { useState, useEffect } from "react";
import { Light as SyntaxHighlighter } from "react-syntax-highlighter";
import python from "react-syntax-highlighter/dist/esm/languages/hljs/python";
import { atomOneDark } from "react-syntax-highlighter/dist/esm/styles/hljs";
import stylesCodeDisplay from "./index.module.css";
import code_delect from "../../lib/assets/code-delect.svg";
import python_ from "../../lib/assets/python_.svg";
import copy_ from "../../lib/assets/copy_.svg";
import yes_ from "../../lib/assets/yes_.svg";

// 注册Python语言
SyntaxHighlighter.registerLanguage("python", python);

const CodeDisplay = ({ code, flag, language = "Python", setFlag }) => {
    const [expanded, setExpanded] = useState(false); // 控制展开状态
    const codeLines = code.trim().split("\n"); // 将代码按行拆分
    const [copySuccess, setCopySuccess] = useState(false); // 复制成功状态

    useEffect(() => {
        setExpanded(flag); // 当 flag 改变时，控制展开/收起状态
    }, [flag]);

    const handleCopy = async () => {
        try {
            await navigator.clipboard.writeText(code);
            setCopySuccess(true);
            setTimeout(() => setCopySuccess(false), 1000); // 1秒后重置状态
        } catch (err) {
            console.error("Failed to copy text: ", err);
        }
    };

    return (
        <>
            <div
                className={`${stylesCodeDisplay.codeDisplay} ${
                    expanded
                        ? stylesCodeDisplay.expanded
                        : stylesCodeDisplay.collapsed
                }`}
            >
                {/* 代码类型展示 */}
                <div className={stylesCodeDisplay.codeHeader}>
                    <img
                        className={stylesCodeDisplay.copyIcon}
                        src={python_}
                        alt="Python"
                    />
                    <div className={stylesCodeDisplay.coptBox}>
                        <button
                            className={stylesCodeDisplay.copyButton}
                            onClick={handleCopy}
                        >
                            {copySuccess ? (
                                <img
                                className={stylesCodeDisplay.copyIcon}
                                src={yes_}
                                alt="CopyIed"
                            />
                            ) : (
                                <img
                                    className={stylesCodeDisplay.copyIcon}
                                    src={copy_}
                                    alt="Copy"
                                />
                            )}
                        </button>
                        <button
                            className={stylesCodeDisplay.copyButton}
                            onClick={() => {
                                setFlag(false);
                            }}
                        >
                            <img
                                className={stylesCodeDisplay.copyIcon}
                                src={code_delect}
                                alt=""
                            />
                        </button>
                    </div>
                </div>

                {/* 显示代码块 */}
                <div className={stylesCodeDisplay.codeContent}>
                    <SyntaxHighlighter
                        language={language}
                        style={atomOneDark}
                        showLineNumbers={true}
                    >
                        {codeLines.join("\n")}
                    </SyntaxHighlighter>
                </div>
            </div>
        </>
    );
};

export default CodeDisplay;
