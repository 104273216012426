import PropTypes from "prop-types";
import React from "react";
import classNames from "classnames";
import VM from "ht-scratch-vm";

import Box from "../box/box.jsx";
import { STAGE_DISPLAY_SIZES } from "../../lib/layout-constants.js";
import StageHeader from "../../containers/stage-header.jsx";
import Stage from "../../containers/stage.jsx";
import Loader from "../loader/loader.jsx";
import CodeDisplay from "../CodeDisplay/index.jsx";
import styles from "./stage-wrapper.css";

const StageWrapperComponent = function (props) {
    const {
        isFullScreen,
        isRtl,
        isRendererSupported,
        loading,
        stageSize,
        vm,
        code,
        flag,
        setFlag,
    } = props;

    return (
        <Box
            className={classNames(styles.stageWrapper, {
                [styles.fullScreen]: isFullScreen,
            })}
            dir={isRtl ? "rtl" : "ltr"}
        >
            <Box className={styles.stageMenuWrapper}>
                <StageHeader
                    flag={flag}
                    setFlag={setFlag}
                    stageSize={stageSize}
                    vm={vm}
                />
            </Box>
            <Box className={styles.stageCanvasWrapper}>
                {/* 代码展示区域 */}
                {/* {flag && (
                    <Box className={styles.codeDisplay}>
                        <pre>
                            <code>{code}</code>
                        </pre>
                    </Box>
                )} */}
                <CodeDisplay code={code} flag={flag} setFlag={setFlag}/>
                {/* 舞台区域 */}
                {isRendererSupported ? (
                    <Stage stageSize={stageSize} vm={vm} />
                ) : null}
            </Box>
            {loading ? <Loader isFullScreen={isFullScreen} /> : null}
        </Box>
    );
};

StageWrapperComponent.propTypes = {
    isFullScreen: PropTypes.bool,
    isRendererSupported: PropTypes.bool.isRequired,
    isRtl: PropTypes.bool.isRequired,
    loading: PropTypes.bool,
    stageSize: PropTypes.oneOf(Object.keys(STAGE_DISPLAY_SIZES)).isRequired,
    vm: PropTypes.instanceOf(VM).isRequired,
    code: PropTypes.string, // 添加了用于展示代码的属性
};

export default StageWrapperComponent;
